import React from "react";
import Typography from "../../components/Typography";

const About = (props) => {
  return (
    <div>
      <Typography variant={"h2"}>About</Typography>
    </div>
  );
};

export default About;
